@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto&display=swap');

.Header-container {
  background: rgb(255,122,0);
  background: radial-gradient(circle, rgba(255,122,0,1) 0%, rgba(0,0,0,1) 91%);
    position: -webkit-sticky;  /* Safari */
    position: sticky;
    top: 0;
    left: 0;
	  right: 0;
    padding: 0 5%;
    /* display: flex;
    flex-direction: row; */
    z-index: 20;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: 100px;
}

.Header-column {
    /* display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 5%;
    left: 2%; */
    float: right;
	padding: 0;
	margin-right: 0;
	width: 60%;
	height: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
} 

.Header-text {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: bold;
    color: white;
    font-size: 250%;
    cursor: pointer;
    letter-spacing: 0.1rem;
    display: block;
	padding: 1em;
}

.logoColor {
    float: left;
    width: 100px;
    height: 100px;
	display: flex;
	align-items: center;
  cursor: pointer;
    
}


/* #header-dropdown {
    fill: white;
} */

.Header-dropdown {
    display: none;
	position: absolute;
	right: 5%;
	top: 50%;
	transform: translateY(-50%);
}

.Header-dropdown {
    width: 30px;
	height: 5px;
	margin: 5px;
	border-radius: 3px;
	transition: all .3s ease-in-out;
    cursor: pointer;
}

header .Header-dropdown .line {
    width: 30px;
	height: 5px;
	background-color: #fff;
	margin: 5px;
	border-radius: 3px;
	transition: all .3s ease-in-out;
}

#nav-toggle {
	position: absolute;
	top: -100px;
}

@media screen and (max-width: 800px){
    .Header-text {
        font-size: 20px;
    }
    .Header-column {
        float: none;
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        top: 100px;
        bottom: 100%;
        width: auto;
        height: auto;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: black;
        overflow: hidden;
        box-sizing: border-box;
        transition: all .5s ease-in-out;
    }
    
    .logoColor {
        float: none;
        width: auto;
        justify-content: center;
        
    }
    .Header-container :checked ~ .Header-column {
        bottom: 0;
    }
    .Header-container .Header-dropdown{
        display: block;
        margin-top: -2%;
    }
    .Header-container :checked ~ .Header-dropdown .line-prueba:nth-child(1) {
		transform: translateY(10px) rotate(225deg);
	}
	.Header-container :checked ~ .Header-dropdown .line-prueba:nth-child(3) {
		transform: translateY(-10px) rotate(-225deg);
	}
	.Header-container :checked ~ .Header-dropdown .line-prueba:nth-child(2) {
		opacity: 0;
	}   
}

/* .navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
  } */
  
/*   .fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
  } */
  
  .nav-menu {
    /* display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 1rem; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    
  }
  
  .nav-item {
    float: right;
	padding: 0;
	margin-right: 0;
	width: 60%;
	height: 100%;
	display: flex;
	align-items: center;
  justify-content: center;
  }
  
  .nav-links {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: bold;
    color: white;
    font-size: 200%;
    cursor: pointer;
    letter-spacing: 0.1rem;
    display: block;
	  padding: 1em;
  }

  .nav-links-galeria {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: bold;
    color: white;
    font-size: 200%;
    cursor: pointer;
    letter-spacing: 0.1rem;
    display: block;
	padding: 1em;
  }
  
  .nav-links:hover,
  .nav-links-galeria:hover {
    transition: all 0.2s ease-out;
    text-shadow: 1px 1px 8px #00000097;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      height: 50vh;
      position: absolute;
      top: 80px;
      right: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      display: none;
      }
  
    .nav-menu.active {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60vh;
      position: absolute;
      top: 99%;
      background: #000000;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      /* margin-left: 10px; */
      box-shadow: 0 250px 10px rgba(0, 0, 0, 0.663);
      right: 0;
      margin-right: -2px;
    } 

   /*  .Header-container :checked ~ .Header-column {
        bottom: 0;
    }
    .Header-container .Header-dropdown{
        display: block;
        margin-top: -2%;
    } */
    /* .Header-dropdown-active {
        .Header-container .Header-dropdown .line:nth-child(1) {
            transform: translateY(10px) rotate(225deg);
        }
        .Header-container .Header-dropdown .line:nth-child(3) {
            transform: translateY(-10px) rotate(-225deg);
        }
        .Header-container .Header-dropdown .line:nth-child(2) {
            opacity: 0;
        }

    } */
  
    .nav-links {
      text-align: center;
      width: 100%;
      display: table;
      border-bottom: 1px solid rgb(222, 222, 222);
    }

    .nav-links-galeria {
      text-align: center;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover,
    .nav-links-galeria:hover {
      background-color: #da6800;
      border-radius: 1px;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 8px;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 800px) and (orientation: landscape) {
    .Header-container {
      height: 75px;
    }
    .logoColor {
      height: 75px;
      width: 75px;
    }
    .nav-menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      height: 50vh;
      position: absolute;
      top: 55px;
      right: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      display: none;
    }
    .nav-menu.active {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 80vh;
      position: absolute;
      top: 100%;
      background: #000000;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      /* margin-left: 10px; */
      box-shadow: 0 250px 10px rgba(0, 0, 0, 0.663);
      right: 0;
      margin-right: -2px;
    } 
    .Header-column {
      float: none;
      position: fixed;
      z-index: 9;
      left: 0;
      right: 0;
      top: 100px;
      bottom: 100%;
      width: auto;
      height: auto;
      flex-direction: column;
      justify-content: space-evenly;
      background-color: black;
      overflow: hidden;
      box-sizing: border-box;
      transition: all .5s ease-in-out;
    }
    .nav-links {
      text-align: center;
      width: 50%;
      display: table;
      border-bottom: 1px solid rgb(222, 222, 222);
      font-size: 150%;
    }

    .nav-links-galeria {
      text-align: center;
      width: 50%;
      display: table;
      font-size: 150%;
    }
    .nav-item {
      justify-content: center;
      height: 70%;
    }
    .menu-icon {
      display: block;
      position: absolute;
      top: -4px;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  }