/* @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@100;300;400&display=swap');


.Tour-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 80%;
        background-color: black;
        padding-top: 5%;
}

.Dates-container {
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    gap: 2%; */
    /* display: grid;
    gap: 5%; */
    /* grid-auto-rows: 22rem; */ 
    /* grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    margin: 5%;  */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
} 

.Tour-date{
    /* width: 90%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between; 
    /* linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(218,104,0,1) 98%); */
    border-radius: 3px;
    width: 270px;
    height: 130px;
    margin: 2%;
    padding: 1%;
    box-shadow: -4px -4px 2px 0 rgba(218,104,0,1);
    /* padding: 5px 25px; */
    /* border-top: 2px solid rgba(218,104,0,1); */
}

.Tour-date > .Tour-calendar > span,
.Tour-date > .Tour-location > .Tour-location-text > span {
    font-size: 100%;
    font-family: "Roboto", sans-serif;
    color: white;
    font-weight: 100;
}

.Dates-tour-data {
    font-size: 100%;
    font-family: "Roboto", sans-serif;
    color: white;
    font-weight: 100;
}

.Tour-svg {
    width: 40px;
    height: 40px;
}

.Tour-title {
    font-family: "Bebas Neue", sans-serif;
    color: #DA6800;
    font-size: 600%;
    text-align: center;
    letter-spacing: 1rem;
    margin-bottom: -2%;
}

#googlecalendar  {
    fill: white;
}

#location  {
    fill: white;
}

.Tour-subtitle {
    font-family: "Bebas Neue", sans-serif;
    color: white;
    font-size: 300%;
    text-align: center;
    letter-spacing: 1rem;
    margin-bottom: 2%;
    border-bottom: 2px solid rgba(218,104,0,1);
}

.Tour-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Tour-calendar {
    display: flex;
    justify-items: flex-start;
    align-items: center;
}

.Tour-location {
    display: flex;
    justify-items: center;
    align-items: center;
}

/* .Tour-location-text {

} */

.Tour-syd {
    font-family: "Bebas Neue", sans-serif;
    color: white;
    font-size: 180%;
    text-align: center;
    letter-spacing: 0.1rem;
    margin-left: 3%;
}

.calendar {
    transform: scale(0.45)
}

.location {
    transform: scale(0.5);
}

.Tour-none {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}

.No-tour-img {
    width: 35%;
    height: 35%;
}

@media screen and (max-width:700px){
    .Tour-title {
        font-size: 400%;
        padding-top: 10%;
    }
    .Tour-subtitle {
        font-size: 200%;
    }
    .Dates-container {
        justify-items: center;
        padding: 7%;
    }
    .Tour-date {
        margin: 6%;
    }
    .No-tour-img {
        margin-top: 4%;
        width: 70%;
        height: 70%;
    }
}


@media screen and (max-width: 960px) and (orientation: landscape){
    .Tour-title{
        font-size: 400%;
    }
    .Tour-subtitle {
        font-size: 230%;
        margin-bottom: 4%;
    }
}
