@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@100;300;400&display=swap');

.BioContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: black;
    padding-top: 5%;
    box-sizing: border-box;
}

.BioBandName {
    font-family: 'Bebas Neue', sans-serif;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 300%;
}

.BioBandTribute {
    font-family: 'Roboto', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 200%;
}

.BioMembers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center; 
}

.BioChicho {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 1000px;
    height: fit-content;
}

.BioGaston,
.BioTira {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: fit-content;
    flex-wrap: wrap; 
}

.Bio-tira-gaston {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap; 
}

.BioMembersInfo {
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 100%;
    text-align: left;
    padding: 2%;
}
.BioMembersInfoVertical {
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 100%;
    text-align: left;
    padding: 2%;
}

.BioMembersInfoContainer {
    display: flex;
    width: 1200px;
    justify-content: center;
    align-items: center;
}


.BioMembersImage {
    width: 700px;
    height: 607px;
}

.BioMembersImage-chicho {
    width: 900px;
    height: 600px;
}

.BioTiraSinFondo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: fit-content;
    flex-wrap: wrap; 
    display: none;
}

.BioDiegoResponsive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: fit-content;
    flex-wrap: wrap; 
    display: none;
}

.Bio-phrase {
    font-family: "Bebas Neue", sans-serif;
    font-size: 200%;
    color: white;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 5%;
}

.Bio-phrase-italic {
    font-style: italic;
}

.BioFirstParagraph,
.BioSecondParagraph,
.BioThirdParagraph {
    font-family: 'Roboto', sans-serif;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 100%;
}

.Bio-title {
    font-family: "Bebas Neue", sans-serif;
    color: #DA6800;
    font-size: 600%;
    text-align: center;
    margin-bottom: 1%;
}

.Members-title {
    font-family: "Bebas Neue", sans-serif;
    color: #DA6800;
    font-size: 500%;
    text-align: center;
    margin: 5%;
}

.Rider-text {
    font-family: "Bebas Neue", sans-serif;
    color: #DA6800;
    font-size: 500%;
    text-align: center;
    margin-bottom: 5%;
    margin-top: 7%;
}

.Rider-tecnico {
    margin: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Rider-title {
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
}

.Rider-info {
    font-family: 'Roboto', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 200%;
    text-align: center;
}

.Bio-image-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5%;
}

.Bio-image-group {
    width: 70%;
    height: auto;
}

.BioText {
    width: 90%;
    margin-left: 3%;
}

.Bio-image-and-phrase {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}



@media screen and (max-width: 860px) {
    .Bio-image-text-container {
        flex-direction: column;
        width: 100%;
    }
    .BioMembersImage {
        width: 70%;
        height: 70%;
    }
    .BioMembersImage-chicho {
        width: 60%;
        height: 60%;
    }
}



@media screen and (max-width: 700px) {
    .Rider-text,
    .Members-title {
        font-size: 250%;
    }
    .Bio-title {
        font-size: 400%;
        padding-top: 10%;
    }
    .Rider-info,
    .BioMembersInfo,
    .BioMembersInfoVertical,
    .BioFirstParagraph,
    .BioSecondParagraph,
    .BioThirdParagraph {
        font-size: 100%;
        font-weight: 100;
        margin: 10px;
    }
    .Bio-phrase {
        font-family: "Roboto", sans-serif;
        font-size: 120%;
        font-weight: 300;
        font-style: italic;
        margin: 10px;
    }
    .BioBandTribute {
        font-size: 120%;
    }
    .Bio-image-and-phrase {
        display: flex;
        flex-direction: column;
    }
    .Rider-tecnico {
        margin-top: 20%;
    }
}


@media screen and (max-width: 1200px) {
    .BioMembersInfo {
        font-family: 'Roboto', sans-serif;
        color: white;
        font-size: 100%;
        text-align: left;
        padding: 2%;
        width: 63%;
        margin-right: -5%;
    }
}

@media screen and (max-width: 960px) {
    .Bio-image-text-container {
        flex-flow: column;
        align-items: center;
    }
    .BioDiegoResponsive,
    .BioTira,
    .BioGaston {
        display: flex;
        flex-flow: column;
        width: auto;
        height: fit-content;
        justify-content: center;
        align-items: center;
    }
    .BioChicho {
        display: none;
    }
    .BioMembers{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .BioMembersImage {
        width: 80%;
        height: auto;
    }
    .BioMembersImage-chicho {
        width: 80%;
        height: auto;
    }
    .Members-title {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .BioBandName {
        margin-bottom: -4%;
    }
    .BioMembersInfo {
        width: 90%;
        margin-right: 0;
        align-items: center;
    }
}


@media screen and (max-width: 960px) and (orientation: landscape) {
    .Bio-title {
    font-size: 400%;
    text-align: center;
    margin-bottom: 1%;
    }
    .BioBandName {
    font-size: 230%;
    }
    .BioBandTribute {
        font-size: 150%;
    }
    .BioFirstParagraph,
    .BioSecondParagraph,
    .BioThirdParagraph {
    font-size: 80%;
    font-weight: 100;
    }
    .BioMembersInfo,
    .BioMembersInfoVertical {
    font-size: 80%;
    font-weight: 100;
    }
    .Members-title,
    .Rider-text {
        font-size: 300%;
    }
    .Rider-info {
        font-weight: 100;
        font-size: 150%;
    }
}