
.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    background-color: black;
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
  }

  .logo {
    position: absolute;
    z-index: 100;
  }