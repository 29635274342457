@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto&display=swap');


.Main-slide {
    background-color: black;
    padding-top: 4%;
} 

.Carousel-container {
    padding-top: 30px;
    max-height: 800px;
}

.Slideshow-title {
    font-family: "Bebas Neue", sans-serif;
    color: #DA6800;
    font-size: 600%;
    text-align: center;
    letter-spacing: 1rem;
    margin-bottom: auto;
}

.carousel .slider-wrapper {
    width: 50%;
}

.carousel .thumb {
    width: 5%;
    height: 50px;
}

.carousel {
    text-align: center;
}

.menosUno{
    position: relative;
    z-index: -1;
}

.carousel .carousel-status {
    color: black;
}

.Carousel-legend {
    color: white;
    font-size: 85%;
}

.Carousel-pic {
    width: 100%;
    height: 100%;
}

.Carousel-phone {
    display: none;
}

.Carousel-pic-and-legend {
    padding: 5%;
    
    text-align: center;
}

@media screen and (max-width:800px){
    .Slideshow-title {
        font-size: 400%;
        padding-top: 10%;
    }
}

@media screen and (max-width:960px) {
    .Carousel-container {
        display: none;
    }
    .Carousel-phone {
        display: block;
    }
}