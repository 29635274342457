@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto&display=swap');

.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  background-color: black;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: black;
}


::-webkit-scrollbar-thumb {
  background-color: #DA6800;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(255, 227, 176);
}

::-webkit-scrollbar-corner {
  background-color: black;
}

.all-components {
  background-color: black;
}

.phone-carousel {
  display: none;
}


@media screen and (max-width:960px) {
  ::-webkit-scrollbar {
    display: none;
  }
  .pc-carousel {
    display: none;
  }
  .phone-carousel {
    display: flex;
  }
}