@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto&display=swap');

.Footer-container {
    background-color: #1a1a1a;
    text-align: center;
    padding-bottom: 5px;
    padding-top: 5px;
}

.Footer-text {
    font-family: "Roboto", sans-serif;
    color: rgb(219, 217, 217);
    font-size: 80%;
}