@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto&display=swap');

.Contact-container {
    width: 100%;
    background-color: black;
    padding-top: 5%;
}

.Contact-second-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 17%;
}


.Contact-logo-and-text {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.Contact-title {
    font-family: "Bebas Neue", sans-serif;
    color: #DA6800;
    font-size: 600%;
    text-align: center;
    margin-bottom: auto;
}

.Contact-social-media {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    letter-spacing: 0.05rem;
    font-size: 130%;
    text-decoration: none;
}

.Contact-social-icons {
    width: 70px;
    height: 70px;
}

.Comunicate-title,
.Seguinos-title {
    font-family: "Bebas Neue", sans-serif;
    color: white;
    font-size: 200%;
    text-align: center;
    letter-spacing: 0.2rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid rgba(218,104,0,1);
}

.Contact-texts {
    border-bottom: 1px solid rgb(255, 255, 255);
}

@media screen and (max-width: 800px) {
    .Contact-second-container {
        display: flex;
        flex-direction: column;
        padding-left: 3%;
        padding-right: 3%;
    }
    .Contact-title {
        font-size: 400%;
        padding-top: 10%;
    }
    .Contact-social-media {
        font-size: 110%;
    }
    .Comunicate-title,
    .Seguinos-title {
        font-size: 150%;
        text-align: left;
        margin-left: 5%;
        margin-right: 5%;
    }
}